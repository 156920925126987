import {useState,useEffect,useRef} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { getIsLogedIn, getIsModalOpen,setIsLogedIn, setIsModalOpen } from './redux/headerApp/headerAppSlice';

import ModalDialog410 from "./components/modals/ModalDialog410";
import NavDropdown from './components/NavDropdown';


//img
import WalletNavIcon from './assets/img/walet-nav-icon.svg'
import SelectArrowIcon from './assets/img/select-arrow-up.svg'
import RecentTransactionIcon from './assets/img/recent-transaction-nav-icon.svg'
import MakeAProfileIcon from './assets/img/profile-nav-icon.svg'
import MarketlaceProfileIcon from './assets/img/marketplace-nav-icon.svg'
import DisconectIcon from './assets/img/disconect-nav-icon.svg'


const navItemsLocal = [
    {title:"Wallet",icon:WalletNavIcon},
    {title:"Resent Transaction",icon:RecentTransactionIcon},
    {title:"Make a Profile",icon:MakeAProfileIcon},
    {title:"Marketplace Profile",icon:MarketlaceProfileIcon},
    {title:"Disconect",icon:DisconectIcon},
]

function App() {
	const body = document.querySelector(`body`)
	const dispatch = useDispatch()
	const isLogedIn = useSelector(getIsLogedIn)
	const isModalOpen = useSelector(getIsModalOpen)

	useEffect(()=>{
		console.log(isModalOpen.id) 
		isModalOpen.id ? body.classList.add("lock") :  body.classList.remove("lock")
	},[isModalOpen.id])

  return (
    <div className="header__btns">
		{!isLogedIn &&  <a href="#" className="btn btn--outline btn--md" data-modal="#modal-1" onClick={()=>dispatch(setIsModalOpen({show:true,id:1}))}>Connect Wallet</a>}
		{isLogedIn && <NavDropdown items={navItemsLocal} navIcon={WalletNavIcon} arrow={SelectArrowIcon} />}
		{isModalOpen.show && <ModalDialog410 />}
    </div>
  );
}

export default App;