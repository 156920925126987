import {useState,useEffect,useRef} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { getIsLogedIn, getIsModalOpen,setIsLogedIn, setIsModalOpen } from '../../redux/headerApp/headerAppSlice';


import WalletItem from './ConnectWallet/WalletItem'

import MetamaskImg from '../../assets/img/logo-metamask.svg'
import BinanceWalletImg from '../../assets/img/logo-binance.svg'
import CoinbaseWalletImg from '../../assets/img/logo-coinbase.svg'
import DotsMenuIcon from '../../assets/img/icon-dots-menu.svg'
import API from "../../api";


const walletsLocal = [
    { title:"Metamask",icon:MetamaskImg},
    {title:"Binance Wallet",icon:BinanceWalletImg},
    {title:"Coinbase Wallet",icon:CoinbaseWalletImg},
    {title:"Metamask",icon:MetamaskImg},
    {title:"Binance Wallet",icon:BinanceWalletImg},
    {title:"Coinbase Wallet",icon:CoinbaseWalletImg},
    {title:"Metamask",icon:MetamaskImg},
    {title:"Binance Wallet",icon:BinanceWalletImg},
]

const ConnectWallet = ({handleCloseModal, modalBody}) =>{
    const dispatch = useDispatch()

    //STATE
    const [wallets,setWallets] = useState([])
    const [moreWallets,setMoreWallets] = useState(false)

    //METHODS
    const handleLogInWallet = (e)=>{
        
        handleCloseModal()
        setTimeout(() => {
            // setIsLoged(true);
            dispatch(setIsLogedIn(true))
        }, 150);
        
    }

    const getWallet = async() => {
        await API.enablePool();
    }

    //EFFECTS
    useEffect(()=>{
        setWallets(walletsLocal)
    },[])

    return (
        <div className="modal__content" ref={modalBody}>
            <div className="modal__header">
                
                <h2 className="modal__title">Connect Wallet</h2>
                <a href="#" className="btn btn-close" data-close-modal="" onClick={(e)=>handleCloseModal()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M8 8L16 16" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M16 8L8 16" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                </a>
            </div>
            <div className="modal__body">
                <ul className="connect-wallet__list">
                    {!moreWallets && wallets.filter((elem,idx)=> idx<=2).map((elem, elemIndex) => 
                        <WalletItem key={elemIndex} title={elem.title} img={elem.icon} handleClick={() => { getWallet(); handleLogInWallet(); }}/>)
                    }
                    {moreWallets && 
                        wallets.map(elem => <WalletItem title={elem.title} img={elem.icon} handleClick={() => { getWallet(); handleLogInWallet(); }}/>)
                    }
                    {!moreWallets &&
                        <WalletItem title={"More"} img={DotsMenuIcon} handleClick={()=>setMoreWallets(true)}/>
                    }
                </ul>
            </div>
        </div>
    )
}

export default ConnectWallet