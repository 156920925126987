import {useState, useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { getIsLogedIn, getIsModalOpen,setIsLogedIn, setIsModalOpen } from '../redux/headerApp/headerAppSlice';

import calculatorIcon from "../assets/img/calculator-icon.svg";
import ModalDialog410 from './modals/ModalDialog410';
import RoiCalculator from "./modals/RoiCalculator";
import Tooltip from './Tooltip';
import API from "../api";

function PoolCardItem() {
    const dispatch = useDispatch()

    const [poolCardOpen, setPoolCardOpen] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [poolData, setPoolData] = useState({
        price: 4.68,
        percent: 2.93,
        flexibleAPY: 60,
        name: "CLOUDIA",
        subText: "Stake, Earn – And more!",
        avatar: "pool_item_logo.png",
        staked: '0.0',
        stakedUsd: 0,
        lockedAPY: 63.28,
        totalStaked: '233,384,419',
        totalLocked: '191,843,687',
        // averageLockDuration: '39 weeks',
        performanceFee: '0~2%',
        recentProfit: {
            staked: '0.0',
            stakedUsd: 0,
            text: '0.1% unstaking fee if withdrawn within 72h'
        }
    })

    const [poolInfo, setPoolInfo] = useState(null);
    const [enablePoolResult, setEnablePoolResult] = useState(null);

    const enablePool = async() => {
        console.log('enablePool');
        await API.enablePool();
        setEnablePoolResult(true);
        setModalIsOpen(true);
    }

    const fetchData = async() => {
        let getW = await API.getWallet();
        dispatch(setIsLogedIn(true))
        console.log('getW:', getW);
        let result = await API.getPoolInfo();
        setPoolInfo(result);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const addToWallet = async() => {
        let result = await API.addToWallet();
        console.log('addToWallet:', result);
    }

    useEffect(() => {
        setTimeout(() => {
            fetchData();
        }, 2000);
    }, []);

    return (
        <>
            { poolInfo && <div className="pool-card-item">
                <div className="pool-card-header">
                    <div className="pool-card-col">
                        <div className="pool-info">
                            <div className="avatar-col relative">
                                { poolData.avatar && <div className="avatar-wrpr">
                                    <img src={`assets/img/${ poolData.avatar }`} alt="" />
                                </div> }
                                <a href="#" className="circle-right-btm-btn">
                                    <img src="assets/img/refresh-icon.svg" alt="" />
                                </a>
                            </div>
                            <div className="info-col">
                                <h3>Stake { poolData.name }</h3>
                                <span className="grey-sm-text">{ poolData.subText }</span>
                            </div>
                        </div>
                    </div>
                    <div className="pool-card-col">
                        <h3 className="grey-sm-text">{ poolData.name } Staked</h3>
                        <div className="flex align-items-end fsz-16 color-white-05">{ poolData.staked } <sub className="sub_text">{ poolData.stakedUsd } USD</sub></div>
                    </div>
                    <div className="pool-card-col">
                        <h3 className="grey-sm-text">Flexible APY</h3>
                        <div className="color-white flex align-items-center">
                            { poolInfo.apy }% 
                            { enablePoolResult && 
                            <a href="#" className="flex ml-5" onClick={(e)=>{e.preventDefault();setModalIsOpen(!modalIsOpen)}}>
                                <img src={ calculatorIcon } alt="" />
                            </a> }
                        </div>
                    </div>
                    {/* <div className="pool-card-col">
                        <h3 className="grey-sm-text">Locked APY</h3>
                        <div className="color-white-05 fsz-16">Up to { poolData.lockedAPY }%</div>
                    </div> */}
                    <div className="pool-card-col">
                        <h3 className="grey-sm-text">Total staked</h3>
                        <div className="color-white fsz-16">{ numberWithCommas(poolInfo.total_staked) } { poolData.name }</div>
                    </div>
                    <div className="pool-card-col">
                        <a 
                            href="#" className={`hide-toggle-btn ${poolCardOpen ? 'active' : ''}`}
                            onClick={ (e)=> {e.preventDefault(); setPoolCardOpen(!poolCardOpen)} }
                        >
                            Hide
                            <img src="assets/img/select-arrow-up.svg" alt="" />
                        </a>
                    </div>
                </div>
                <div className={`pool-card-body pool-card-body-1 ${poolCardOpen ? 'active' : ''}`}>
                    <ul className="info-list info-list-column">
                        <li>
                            <div className="sides-wrpr">
                                <div>Total locked:</div>
                                <div className="color-white">{ poolData.totalLocked } { poolData.name }</div>
                            </div>
                        </li>
                        {/* <li>
                            <div className="sides-wrpr">
                                <Tooltip content={`The average lock duration of all the locked staking positions of other users`} style={{marginLeft:"0px"}} placement={'bottom-end'}>
                                    <a href="#" className="text-style-underline">Average lock duration:</a>
                                </Tooltip>
                                <div className="color-white">{ poolData.averageLockDuration }</div>
                            </div>
                        </li> */}
                        <li>
                            <div className="sides-wrpr">
                                <Tooltip content={`Performance fee only applies to the flexible staking rewards.`} style={{marginLeft:"0px"}} placement={'bottom-end'}>
                                    <a href="#" className="text-style-underline">Performance Fee</a>
                                </Tooltip>
                                <div className="color-white">0~{ poolInfo.performance_fee }%</div>
                            </div>
                        </li>
                        <li>
                            <a href="#" className="color-pink flex items-center">See Token Info <img src="assets/img/link-pink-icon.svg" alt="" className="ml-5" /></a>
                        </li>
                        <li>
                            <a href="#" className="color-pink flex items-center">View Tutorial <img src="assets/img/link-pink-icon.svg" alt="" className="ml-5" /></a>
                        </li>
                        { API.cld2 && <li>
                            <a href={"https://bscscan.com/address/"+API.cld2.address} target="_blank" className="color-pink flex items-center">
                                View Contract <img src="assets/img/link-pink-icon.svg" alt="" className="ml-5" />
                            </a>
                        </li> }
                        <li>
                            <a href="#" className="color-purple flex items-center" onClick={ addToWallet }>
                                Add to Wallet <img src="assets/img/walet_icon.svg" alt="" className="ml-5" />
                            </a>
                        </li>
                        <li className="flex items-center">
                            <a href="#" className="btn btn--sm btn--outline-green">
                                <img src="assets/img/refresh-icon.svg" alt="" className="mr-5" />
                                Auto
                            </a>
                            <a 
                                href="#" className="ml-10"
                                // data-tippy-content="Rewards are distributed and included into your staking balance automatically. There’s no need to manually compound your rewards."
                            >
                                <Tooltip content={`
                                    <span>Rewards are distributed and included into your staking balance automatically. There’s no need to manually compound your rewards.
                                    </span>`} placement={'bottom-end'} style={{marginLeft:"0px"}}>
                                    <img src="assets/img/tooltip_help_icon.svg" alt="" />
                                 </Tooltip>
                            </a>
                        </li>
                    </ul>
                    <div className="pool-infobox-wrpr">
                        <div className="pool-body-infobox align-self-start ml-a">
                            <h3 className="infobox-title">Recent { poolData.name } profit</h3>
                            <ul className="info-list">
                                <li>
                                    <div className="sides-wrpr">
                                        <div className="flex align-items-end fsz-16 color-white-05 white-space-nowrap">{ poolData.recentProfit.staked } <sub className="sub_text">{ poolData.recentProfit.stakedUsd } USD</sub></div>
                                        <a href="#" className="color-white text-style-underline fsz-14 max-w-188 text-align-right">
                                            { poolInfo.withdraw_fee }% unstaking fee if withdrawn within { poolInfo.withdraw_fee_period }h
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="pool-body-infobox align-self-start">
                            <h3 className="infobox-title">ENABLE POOL</h3>
                            {!enablePoolResult && <a
                                href="#" className="btn btn--outline btn--md w-100"
                                // onClick={() => dispatch(setIsModalOpen({show:true,id:1}))}
                                onClick={(e) => {e.preventDefault(); enablePool(); }}
                            >Enable</a> }
                            {enablePoolResult && 
                                <p
                                    style={{
                                        color: '#fff',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '8px'
                                    }}
                                >Enabled</p>
                            }
                        </div>
                    </div>
                </div>
            </div> }
            { !poolInfo && <h2 style={{ textAlign: 'center' }}>Loading...</h2>}

            {modalIsOpen &&
                <RoiCalculator
                    poolData={poolData}
                    close={() => setModalIsOpen(false)}
                />
            }
        </>
    );
  }
  
  export default PoolCardItem;