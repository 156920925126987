// import { ethers } from "https://cdn.ethers.io/lib/ethers-5.2.esm.min.js";

// import web3 from 'web3';
const web3 = window.web3;
const ethers = window.ethers;

class API{
	constructor(){
		this.init();
	}
	
	async init(){
		this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
		await this.checkNetwork();
		
		this.signer = await this.provider.getSigner();
//		this.cld = await this.getContract("Cloudia");
//		this.dummy = await this.getContract("DummyToken");
//		this.pool = await this.getContract("CloudiaPool");
//		this.cloud = await this.getContract("MasterCloud");
		
		this.cld2 = await this.getContract("Cloudia-prod");
		this.pool2 = await this.getContract("CloudiaPool-prod");
		this.cloud2 = await this.getContract("MasterCloud-prod");
		
		//console.log("after init?");
	}
	
	async getPoolInfo(){	
		let total_tokens = 100000000*10**18;
		let reward_per_block = (await this.cloud2.rewardPerBlock()).toString(); 
		let apy = +parseFloat((reward_per_block*28800*365)/total_tokens*100).toFixed(6);
		
		let info = {
			total_staked: ((await this.cloud2.poolInfo(0))[3]).toString(),
			//performance_fee: (await this.pool2.performanceFee()).toString(),
			performance_fee: (await this.pool2.performanceFee()).toString()/100,
			withdraw_fee_period: (await this.pool2.withdrawFeePeriod()).toString()/3600,
			withdraw_fee: (await this.pool2.withdrawFee()).toString()/100,
			min_deposit_amount: (await this.pool2.MIN_DEPOSIT_AMOUNT()).toString(),
			total_tokens: total_tokens,
			reward_per_block: reward_per_block,
			apy: apy//(reward_per_block*block_per_day*365)/total_coins
		};
		
		return info;
	}
	
	async deposit(count=""){ //передавать стрингу
		try{
			return await this.pool2.deposit(count);
		}catch(e){
			console.log(e);
			return e;
			// alert(e.data.message);
		}
	}
	
	//подключиться к пулу
	async enablePool(){
		//await this.checkNetwork();
		this.wallet =  await this.getWallet();
		// await this.cld2.approveForAll(this.pool2.address);
		await this.cld2.approve(this.pool2.address, await this.cld2.balanceOf(this.wallet));
		return this.wallet;
	}
	
	
	async getWallet(){
		//await this.checkNetwork();
		return this.wallet = (await this.provider.send("eth_requestAccounts", []))[0];
	}
	
	async checkNetwork(){
		this.network = await this.provider.getNetwork();
		if(this.network.chainId!=56){
			if(true){
				await window.ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [{
						chainId: '0x38',
						chainName: 'Binance Smart Chain',
						nativeCurrency: {
							name: 'Binance Coin',
							symbol: 'bnb',
							decimals: 18
						},
						rpcUrls: ['https://bsc-dataseed.binance.org/'],
						blockExplorerUrls: ['https://bscscan.com/']
					}]
				})
				.catch((error) => {
					if(error.code=='-32602'){
						console.log("ok");
					}
					//alert("add warning popup here");
				});
				
				await this.provider.send('wallet_switchEthereumChain', [{chainId: '0x38'}]);
				
			}
			this.provider = new ethers.providers.Web3Provider(web3.currentProvider);
			this.network = await this.provider.getNetwork();
			
		}
	}
	
//	async addPool(cnt=1000, token="0x7bc06c482DEAd17c0e297aFbC32f6e63d3846650", update=false){
//		return await this.cloud.add(cnt, token, update);
//	}
//	
	async getContract(name=""){
		let deploy_info = (await fetch(`/${name}.json?v=`+Date.now()).then(r=>r.json())) 
		
		// let deploy_info = require(`./${name}.json`);
		const {abi, address} = deploy_info.contract;
		return new ethers.Contract(address, abi, this.signer);
	}	

	async addToWallet(){
		return await window.ethereum.request({
			method: 'wallet_watchAsset',
			params: {
			type: 'ERC20', 
				options: {
					address: this.cld2.address,
					symbol: "$CLD", 
					decimals: 4, 
				},
			},
		});
	}

}


//let t1 = new CloudiaFront();


window.API = new API();
export default window.API;
