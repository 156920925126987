import { configureStore } from '@reduxjs/toolkit';
// import { contactsSliceReducer } from './contactsSlice/contactsSlice';
import { headerAppSliceReducer } from './headerApp/headerAppSlice';
import { poolAppReducer } from './poolApp/poolAppSlice';


// store
export const store = configureStore({
  reducer: {
    // placeholder: placeholderReducer,
    headerApp: headerAppSliceReducer,
    poolApp: poolAppReducer,
  },
});
