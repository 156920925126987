import React from "react";

import {useEffect} from "react";

export const useClickOutsideSingle = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            const element = ref?.current;
            if(!element || element.contains(event.target)) {
                return;
            }
            setTimeout(() => {
                handler(event);
            }, 100);
        }
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }

    }, [ref, handler]);
}
