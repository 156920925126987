import React from 'react';
import Tippy from 'tippy.js';

const defaultOpts = {
	placement: 'top',
    interactive: true,
    maxWidth: 560,
    allowHTML: true,
}

export default class Tooltip extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.initTippy();
	}

	componentWillUnmount() {
		this.destroyTippy();
	}

	initTippy = () => {
		if (typeof window === 'undefined' || typeof document === 'undefined') {
			return;
		}
		//this.tooltipDOM.setAttribute('title', this.props.title);
		this.tippy = Tippy(this.tooltipDOM, { ...defaultOpts, content: this.props.content, maxWidth:this.props.maxWidth,interactive:this.props.interactive, placement:this.props.placement});
	}

	destroyTippy = () => {
		if (typeof window === 'undefined' || typeof document === 'undefined') {
			return;
		}
		if (this.tippy) {
			this.tooltipDOM._tippy.destroy();
			this.tooltipDOM = null;
			this.tippy = null;
		}
	}

	render() {
		return (
			<div
				style={{
					display: 'inline',
					marginLeft: '8px',
					...this.props.style
				}}
				className="popover color-info icon-ico-alert-baseinfo1"
				ref={(tooltip) => this.tooltipDOM = tooltip}
			>
				{this.props.children}
			</div>
		);
	}
}