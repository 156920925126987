import {useState,useEffect,useRef} from 'react'

import CopyIcon from '../assets/img/icon-copy.svg' 

const  CopyToClickboard = ({text,addClass}) => {

    const [isCopied,setIsCopied] = useState(false)


    const copy = () => {
            function fallbackCopyTextToClipboard(text) {
                var textArea = document.createElement("textarea");
                textArea.value = text;
                
                // Avoid scrolling to bottom
                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";
              
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
              
                try {
                  var successful = document.execCommand('copy');
                  var msg = successful ? 'successful' : 'unsuccessful';
                  console.log('Fallback: Copying text command was ' + msg);
                } catch (err) {
                  console.error('Fallback: Oops, unable to copy', err);
                }
              
                document.body.removeChild(textArea);
            }
    
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
            } else {
                navigator.clipboard.writeText(text)
            }
    
            setIsCopied(true)

            console.log(JSON.stringify(isCopied))
    
            setTimeout(() => {
                setIsCopied(false)
                console.log(JSON.stringify(isCopied))
            }, 1500)
    
        };
    

    const copiedClass = () => isCopied ? 'copy-element copytrue' : 'copy-element';
        return (
            <div className={`${copiedClass()} +  ${addClass}`} data-copy>
                <div className="copy-element--text">
                    {!isCopied && <span className="text">{ text }</span>}
                    {isCopied && <span className="copied-complete">
                        <span className="text text-complete">Copied to clipboard</span>
                    </span>}
                </div>
                <button className="copy-element--btn btn base-primary" data-copy-btn onClick={() => copy()}>
                    <img src={CopyIcon} alt='icon'/>
                </button>
            </div>
        )
}

export default CopyToClickboard