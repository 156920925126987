import { createSlice } from '@reduxjs/toolkit';

// const pending = state => {
//     state.isLoading = true;
//     state.error = null;
// };
// const rejected = (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
// };

const poolApp = createSlice({
    name:"poolApp",
    initialState:{
        someStoreValue: '',
    },


    reducers: {
        setSomeStoreValue: (state, action) => {
            state.someStoreValue = action.payload;
        },
    },
    extraReducers: {
        // await operations to API and set to state
        // example
        //         [nameThunk.fulfilled]: (state, action) => {
        //          console.log(action);
        //          state.nameFieldInState = action.payload;
        //          state.isLoading = false;
        //          state.error = null;
        //          },
        //          [nameThunk.pending]: state => {
        //          pending(state);
        //          },
        //          [nameThunk.rejected]: (state, action) => {
        //          rejected(state, action);
        //          },
    },
});

export const poolAppReducer = poolApp.reducer;
export const {setSomeStoreValue} = poolApp.actions;

// Selectors
// export const getSomeStoreValue = state => state.poolApp.someStoreValue;

