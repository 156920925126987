import { createSlice } from '@reduxjs/toolkit';

// const pending = state => {
//     state.isLoading = true;
//     state.error = null;
// };
// const rejected = (state, action) => {
//     state.isLoading = false;
//     state.error = action.payload;
// };

const headerAppSlice = createSlice({
    name:"headerApp",
    initialState:{
        isModalOpen: {show:false,id:null},
        isLogedIn:false,
    },

    reducers: {
        // =================   local operations to state ==================
        setIsLogedIn: (state, action) => {
            state.isLogedIn = action.payload;
        },
        setIsModalOpen: (state, action)=>{
            state.isModalOpen = action.payload;
        },
    },
});

export const headerAppSliceReducer = headerAppSlice.reducer;
export const {setIsLogedIn, setIsModalOpen} = headerAppSlice.actions;

// Selectors
export const getIsModalOpen = state => state.headerApp.isModalOpen;
export const getIsLogedIn = state => state.headerApp.isLogedIn;
