import {useState,useEffect,useRef} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { getIsLogedIn, getIsModalOpen,setIsLogedIn, setIsModalOpen } from '../../redux/headerApp/headerAppSlice';

import { createPortal } from 'react-dom';
import { useClickOutsideSingle } from '../../hooks/useClickOutsideSingle';

import ConnectWallet from './ConnectWallet'
import RoiCalculator from './RoiCalculator'
import YourWallet from './YourWallet'

const modalRootOne = document.querySelector('#modal-root')

const ModalDialog410 = () =>{
    const dispatch = useDispatch()
    const isModalOpen = useSelector(getIsModalOpen)

    const modal = useRef(null)
    const modalBody = useRef(null)

    const handleCloseModal = ()=>{
        modal.current.classList.remove("modal--show")
        setTimeout(()=>dispatch(setIsModalOpen({show:false,id:null})),150)
    }

    useEffect(()=>{

        if(isModalOpen.id  && modal.current){
            setTimeout(()=> isModalOpen.id  && modal.current.classList.add("modal--show"),10)
        }
    },[isModalOpen.id ,modal.current])

    useClickOutsideSingle(modalBody,handleCloseModal)


    return createPortal(
        <div id={`modal-${isModalOpen.id }`} className={`modal modal-${isModalOpen.id } `} ref={modal}>
            <div className="modal__dialog modal__dialog--410">
                {isModalOpen.id  === 1 && <ConnectWallet handleCloseModal={handleCloseModal} modalBody={modalBody}/>}
                {isModalOpen.id  === 2 && <YourWallet handleCloseModal={handleCloseModal} modalBody={modalBody}/>}
                {/* {modalId === 3 && <RoiCalculator/>} */}
            </div>
        </div>,modalRootOne
    )
}

export default ModalDialog410