import {useState,useEffect,useRef} from 'react';
import { createPortal } from 'react-dom';
import Tooltip from '../Tooltip';
import API from "../../api";

const modalRoot2 = document.querySelector('#modal-root-2');

const RoiCalculator = ({close, poolData}) =>{
    const [modalShow, setModalShow] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const mainTabs = ["Flexible", "Locked"];
    const [mainTabActive, setMainTabActive] = useState('Flexible');
    const [payload, setPayload] = useState({
        stackedUSD: 0,
        stackedAmount: 0.00,
        stakedFor: '1Y',
        weekDuration: 1,
        summ: 0,
        percents: 0,
        amount: 0,
    });
    const stackedForArray = ["1D", "7D", "30D", "1Y", "5Y"];
    const addDurationArray = [1, 5, 10, 25];
    const [cakeStakedFlip, setCakeStakedFlip] = useState(false);
    const [localPoolData, setLocalPoolData] = useState({
        price: 0,
        percent: 0,
    });
    const [editSumm, setEditSumm] = useState(false);

    const [poolInfo, setPoolInfo] = useState(null);
    const [depositResult, setDepositResult] = useState(null);

    const changePayload = (field, value, tabActiveArg = null) => {
        setPayload((prevPayload) => {
            let { stackedUSD, stackedAmount, summ, stakedFor, percents, weekDuration, amount } = prevPayload;
            let tabActive = tabActiveArg ? tabActiveArg : mainTabActive;

            if(field === 'stackedUSD'){
                stackedUSD = value;

                stackedAmount = +(+value / +localPoolData.price);
            }

            if(field === 'stakedFor'){
                stakedFor = value;
            }
            if(field === 'weekDuration'){
                weekDuration = value;
            }

            if(field === 'stackedAmount'){
                stackedAmount = value;

                stackedUSD = +(+value * +localPoolData.price);
            }

            if(field === 'summ'){
                summ = value;
                stackedUSD = +((+value / +percents) * 100);

                stackedAmount = +(+stackedUSD / +localPoolData.price);
            } else {
                if(tabActive === 'Flexible'){
                    switch (stakedFor) {
                        case '1D':
                            summ = (((localPoolData.percent/365) / 100) * stackedUSD).toFixed(2);
                            percents = +(localPoolData.percent/365).toFixed(2);
                            break;
                        case '7D':
                            summ = (((localPoolData.percent/52) / 100) * stackedUSD).toFixed(2);
                            percents = +(localPoolData.percent/52).toFixed(2);
                            break;
                        case '30D':
                            summ = (((localPoolData.percent/12) / 100) * stackedUSD).toFixed(2);
                            percents = +(localPoolData.percent/12).toFixed(2);
                            break;
                        case '1Y':
                            summ = ((localPoolData.percent / 100) * stackedUSD).toFixed(2);
                            percents = +(localPoolData.percent).toFixed(2);
                            break;
                        case '5Y':
                            summ = (((localPoolData.percent*5) / 100) * stackedUSD).toFixed(2);
                            percents = +(localPoolData.percent*5).toFixed(2);
                            break;
                        default:
                            break;
                    }
                } else {
                    summ = ((localPoolData.percent / (100 - weekDuration)) / 100) * stackedUSD;
                    percents = +(localPoolData.percent / (100 - weekDuration)).toFixed(2);
                }                
            }

            amount = +(+summ / +localPoolData.price).toFixed(3);

            return {...prevPayload, stackedUSD, stackedAmount, summ, stakedFor, percents, weekDuration, amount }
        })
    }

    const handleClose = (e = null) => {
        if(e) e.preventDefault();
        setModalShow(false);

        setTimeout(() => {
            close();
        }, 150);
    }

    const clickOutside = (e) => {
        if(e.target.classList.contains('modal') || e.target.classList.contains('modal__dialog')){
            handleClose();
        }
    }

    const fetchData = async() => {
        let result = await API.getPoolInfo();
        console.log('poolInfo:', result);
        setPoolInfo(result);
    }

    const deposit = async() => {
        let result = await API.deposit(`${payload.amount.toFixed(0)}`);
        console.log('deposit:', result);
        setModalShow(false);

        setDepositResult(result);
        setTimeout(() => {
            setModalShow(true);
        }, 150);
    }

    useEffect(() => {
        document.addEventListener("click", clickOutside, true);
        setLocalPoolData(poolData);

        setTimeout(() => {
            setModalShow(true);
        }, 150);

        setTimeout(() => {
            fetchData();
        }, 2000);

        return () => {
            document.removeEventListener("click", clickOutside, true);
            setDepositResult(null);
        }
    }, []);

    return createPortal(
        <>
            <div id="modal-3" className={`modal modal-3 ${modalShow ? 'modal--show' : ''}`}>
                <div className="modal__dialog modal__dialog--410">
                    <div className="modal__content">
                        <div className="modal__header">
                            <h2 className="modal__title">Roi Calculator</h2>
                            <a href="#" className="btn btn-close" onClick={ handleClose }>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M8 8L16 16" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M16 8L8 16" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        { !depositResult && <>
                            <div className="modal__body">
                                <div className="tab-wrapper">
                                    {/* <nav className="tab-nav mb-20">
                                        <ul className="tab-nav-list">
                                            {
                                                mainTabs.map((tab, index) => 
                                                    <li 
                                                        className={`tab-nav-item ${index === 0 ? 'mr-5' : ''} ${mainTabActive === tab ? 'active-tab' : ''}`} key={ index }
                                                        onClick={() => { setMainTabActive(tab);changePayload(null, null, tab); }}
                                                    >
                                                        <div className="btn">
                                                            <span>{ tab }</span>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </nav> */}

                                    <div className="tab-content">
                                        <div className="tab active-tab" id="flexible">
                                            <h4 className="mb-8">CLOUDIA staked</h4>
                                            <div className="pool-body-infobox max-w-100 flex align-items-center justify-content-end mb-10">
                                                <div className="mr-14 text-align-right">
                                                    <div className="fsz-16 mb-7">
                                                        <input
                                                            type="text" placeholder="0.00" className="input-transparent" 
                                                            onInput={(e) => {changePayload(cakeStakedFlip ? 'stackedAmount' : 'stackedUSD', e.target.value)}}
                                                            value={ cakeStakedFlip ? payload.stackedAmount : payload.stackedUSD }
                                                        /> { cakeStakedFlip ? 'CLOUDIA' : 'USD' }
                                                    </div>
                                                    <div className="fsz-12">
                                                        { cakeStakedFlip ? 
                                                            payload.stackedUSD : 
                                                            payload.stackedAmount 
                                                        } { cakeStakedFlip ? ' USD' : ' CLOUDIA' }
                                                    </div>
                                                </div>
                                                <a href="#" className="flex" onClick={(e) => { e.preventDefault(); setCakeStakedFlip(!cakeStakedFlip); }}>
                                                    <img src="assets/img/arrange-arrows-icon.svg" alt="" />
                                                </a>
                                            </div>
                                            <div className="staked__list mb-20">
                                                <button className="btn btn--outline btn--md" onClick={() => { changePayload('stackedUSD', 100) }}>$100</button>
                                                <button className="btn btn--outline btn--md" onClick={() => { changePayload('stackedUSD', 1000) }}>$1000</button>
                                                <button className="btn btn--flat btn--md">My Balance</button>
                                                {/* <div className="d-flex" data-tippy-content="Here includes both CLOUDIA in your wallet, and CLOUDIA already staked in this pool.">
                                                    <img src="assets/img/question-circle.svg" />
                                                </div> */}
                                                <Tooltip content={`
                                                    <span>“My Balance” here includes both CAKE in your wallet, and CAKE already staked in this pool.</span>`}
                                                    style={{
                                                        display: 'flex',
                                                        marginLeft: '0px',
                                                    }}
                                                >
                                                    <img src="assets/img/question-circle.svg" />
                                                </Tooltip>
                                            </div>
                                            { mainTabActive === mainTabs[0] && <>
                                                <h4 className="mb-8">Staked for</h4>
                                                <div className="mb-20 pb-20 border-bottom">
                                                    <ul className="duration__list">
                                                        {
                                                            stackedForArray.map((item, index) => 
                                                            <li key={ index }>
                                                                <button
                                                                    className={`duration__item ${payload.stakedFor === item ? 'active' : ''}`}
                                                                    onClick={() => { changePayload('stakedFor', item) }}
                                                                >
                                                                    { item }
                                                                </button>
                                                            </li>)
                                                        }
                                                    </ul>
                                                </div>
                                            </> }
                                            { mainTabActive === mainTabs[1] && <>
                                                <h4 className="mb-8">Add duration</h4>
                                                <ul className="duration__list mb-8">
                                                    {
                                                        addDurationArray.map((item, index) => 
                                                        <li key={ index }>
                                                            <button
                                                                className={`duration__item ${+payload.weekDuration === +item ? 'active' : ''}`}
                                                                onClick={() => { changePayload('weekDuration', +item) }}
                                                            >
                                                                { item }W
                                                            </button>
                                                        </li>)
                                                    }
                                                    <li>
                                                        <button
                                                            className={`duration__item ${+payload.weekDuration === 52 ? 'active' : ''}`}
                                                            onClick={() => { changePayload('weekDuration', 52) }}
                                                        >Max</button>
                                                    </li>
                                                </ul>
                                                <div className="mb-20 pb-20 border-bottom">
                                                    <div className="input-wrapp">
                                                        <input
                                                            className="input-custom" type="text" placeholder="0"
                                                            onInput={(e) => {changePayload('weekDuration', e.target.value)}}
                                                            value={ payload.weekDuration }
                                                        />
                                                        <span className="input-text-right">Week</span>
                                                    </div>
                                                </div>
                                            </> }

                                            <div className="mb-20 pb-20 border-bottom">
                                                <div className="pool-body-infobox max-w-100 flex align-items-center justify-sb mb-10">
                                                    <div className="mr-14 text-left">
                                                        <div className="fsz-12 mb-14">ROI at current rates</div>
                                                        { !editSumm && <div className="fsz-16 color-white mb-7">${ payload.summ }</div> }
                                                        { editSumm && <div className="edit-summ-box">
                                                            <span className="pre-input-symbol">$</span>
                                                            <input type="text" value={ payload.summ } onInput={(e) => {changePayload('summ', e.target.value)}} />
                                                        </div> }
                                                        <div className="fsz-12">~ { payload.amount } CLOUDIA ({ payload.percents }%)</div>
                                                    </div>
                                                    <a href="#" className="flex mt-25 edit-summ-btn" onClick={(e) => { e.preventDefault(); setEditSumm(!editSumm); }}>
                                                        { !editSumm && <img src="assets/img/icon-edit.svg" alt="" /> }
                                                        { editSumm && 
                                                        <svg 
                                                            viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg"
                                                            style={{ fill: 'var(--defaultTextColor)' }}
                                                        >
                                                            <path d="M9.00012 16.2L5.50012 12.7C5.11012 12.31 4.49012 12.31 4.10012 12.7C3.71012 13.09 3.71012 13.71 4.10012 14.1L8.29012 18.29C8.68012 18.68 9.31012 18.68 9.70012 18.29L20.3001 7.70001C20.6901 7.31001 20.6901 6.69001 20.3001 6.30001C19.9101 5.91001 19.2901 5.91001 18.9001 6.30001L9.00012 16.2Z"></path>
                                                        </svg> }
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal__footer">
                                <button className="btn btn--outline btn--md w-100" onClick={ deposit }>Deposit</button>
                            </div>
                            <div className="modal__footer">
                                <div className={`details ${detailsOpen ? 'active' : ''}`} data-spoller="">
                                    <a href="#" className="details__btn" data-spoller-btn="" onClick={() => { setDetailsOpen(!detailsOpen); }}>
                                        <span className="text-open">Hide</span>
                                        <span className="text-closed">Details</span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.66667 8.33333L10 11.6667L13.3333 8.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </a>
                                    { detailsOpen && <div className="mb-10" data-spoller-drop="">
                                        <div className="details__head">
                                            <span>APY</span>
                                            <span>{ localPoolData.percent }%</span>
                                        </div>
                                        <ul className="details__list">
                                            <li>Calculated based on current rates.</li>
                                            <li>All figures are estimates provided for your convenience only, and by no means represent guaranteed returns.</li>
                                        </ul>
                                    </div> }
                                </div>
                            </div>
                        </> }
                        { depositResult && <div className="modal__body" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                            <p style={{ color: depositResult.code === -32603 ? "red" : "#ffffff" }}>{ depositResult.message }</p>
                        </div> }
                    </div>
                </div>
            </div>
        </>,
        modalRoot2
    )
}

export default RoiCalculator