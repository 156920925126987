import {useState,useEffect,useRef} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { getIsLogedIn, getIsModalOpen,setIsLogedIn, setIsModalOpen } from '../../redux/headerApp/headerAppSlice';

import ModalRadioNav from './ModalRadioNav'

// img
import CopyIcon from '../../assets/img/icon-copy.svg' 
import BNBChainIcon from '../../assets/img/logo-bnb2.png' 
import CheckMarckCircleIcon from '../../assets/img/icon-checkmark-circle.svg'
import CopyToClickBoard from '../CopyToClickboard'


const localTabItems = [
    {title:'Wallet'},
    {title:'Transactions'},
]
const localTransactionsItems = [
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
    {name:'Swap 0.011 BNB for 7740000 BEP20',status:true},
]


const YourWallet = ({handleCloseModal, modalBody}) =>{
    const dispatch = useDispatch()

    // STATE
    const [modalType,setModalType] = useState("wallet")

    // METHODS
    const handleLogOutWallet = (e)=>{
        
        handleCloseModal()
        setTimeout(() => {
            dispatch(setIsLogedIn(false))
        }, 150);
        
    }

    //EFFECTS
    

    return (
        <div className="modal__content" ref={modalBody}>
            <div className="modal__header">
                <h2 className="modal__title">Your Wallet</h2>
                <a href="#" className="btn btn-close" data-close-modal="" onClick={()=>handleCloseModal()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M8 8L16 16" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M16 8L8 16" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                    </svg>
                </a>
            </div>
            <div className="modal__body">
                <div className="tab-wrapper">
                    <ModalRadioNav items={localTabItems} activeTab={modalType} setActiveTab={setModalType}/>
                    <div className="tab-content">
                        {modalType === "wallet" && 
                            <div className={`tab ${modalType === "wallet" ? "active-tab" : ''}`} id="wallet">
                                <h4 className="mb-8">Your address</h4>
                                <CopyToClickBoard addClass={'copy-element mb-20'} text={'0x036B40f710b...63bDd6b4c313081eb9E2'}/>
                                <div className="balance__wrap mb-20">
                                    <div className="balance__header">
                                        <div className="balance__name">
                                            <img src={BNBChainIcon} alt='icon'/>
                                            <span>BNB Smart Chain</span>
                                        </div>
                                        <a href="#" className="link-share-arrow">
                                            <span className="info mr-5">BscScan</span>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23473 7.45808C5.16136 7.91779 2.08221 11.3622 2.08008 15.4614V15.9716C3.85039 13.839 6.46346 12.5855 9.23473 12.5394V15.2296C9.23482 15.62 9.45794 15.976 9.80922 16.1462C10.1605 16.3164 10.5782 16.271 10.8846 16.0291L17.5457 10.7695C17.7822 10.5831 17.9202 10.2986 17.9202 9.99747C17.9202 9.69636 17.7822 9.41186 17.5457 9.22548L10.8846 3.96579C10.5782 3.72398 10.1605 3.67852 9.80922 3.84875C9.45794 4.01897 9.23482 4.37494 9.23473 4.76529V7.45808Z" stroke="#FC12D0" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="balance__item">
                                        <span>BNB Balance</span>
                                        <span>0.0</span>
                                    </div>
                                    <div className="balance__item">
                                        <span>CLOUDIA Balance</span>
                                        <span>0.0</span>
                                    </div>
                                </div>
                                <button className="btn btn--outline btn--md w-100" onClick={()=>handleLogOutWallet()}>Disconnect Wallet</button>
                            </div>
                        }
                        {modalType === "transactions" && 
                            <div className={`tab ${modalType === "transactions" ? "active-tab" : ''}`} id="transactions">
                                <h4 className="mb-8">Recent transactions</h4>
                                <ul className="transaction-list">
                                    {localTransactionsItems.map(elem=>
                                        <li className="transaction-item">
                                            <span className="transaction-name">{elem.name}</span>
                                            <div className="transaction-check">
                                                <img src={CheckMarckCircleIcon} alt='icon'/>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YourWallet