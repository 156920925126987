import {useState,useEffect,useRef} from 'react'

const ModalRadioNav = ({items,activeTab,setActiveTab}) =>{


    return (
    <nav className="tab-nav mb-20">
        <ul className="tab-nav-list">
            {items.map(elem=>
                <li className={`tab-nav-item mr-5 ${activeTab === elem.title.toLowerCase() ? "active-tab" : ""}`} data-href="#wallet" onClick={(e)=>setActiveTab(elem.title.toLowerCase())}>
                    <button className="btn">
                        <span>{elem.title}</span>
                    </button>
                </li>)
            }
        </ul>
    </nav>
    )
}

export default ModalRadioNav