import {useState, useEffect} from 'react'

import PoolCardItem from "./components/PoolCardItem.js";

function Pools() {

    return (
        <div className="blog-all pt-100">
            {/* <h2 class="section-title mb-12 text-center">Pools</h2>
            <div class="section-subtitle mb-60 text-center">
                <p>Just stake some tokens to earn. High APR, low risk.</p>
            </div> */}

            <PoolCardItem />
        </div>
    );
  }
  
  export default Pools;