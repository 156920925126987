import {useState,useEffect,useRef} from'react'
import { useSelector,useDispatch } from 'react-redux';
import { getIsLogedIn, getIsModalOpen,setIsLogedIn, setIsModalOpen } from '../redux/headerApp/headerAppSlice';

const NavDropdown = ({items,navIcon,arrow,}) =>{
    const dispatch = useDispatch()


    const handleClick = (e,elem) =>{
        if(elem.title ==="Wallet"){
            dispatch(setIsModalOpen({show:true,id:2}))
        }
        if(elem.title ==="Disconect"){
            dispatch(setIsLogedIn(false))
        }
    }

    const walletNumber = window?.API?.wallet;

    return (
        <div className="header__dropdown" data-header-dropdown="">
                    <a href="#" className="btn btn--outline btn--md" data-header-dropdown-btn="">
                        {navIcon ? <img src={navIcon} alt="" className="mr-8 icon-filter-shadow"/>:""} <span style={{ maxWidth: '100px', overflow: 'hidden',textOverflow: 'ellipsis' }}>{ walletNumber }</span> {arrow ? <img src={arrow} alt="" className="ml-10 select-arrow-up"/>: ''}
                    </a>
                    <div className="header__dropdown-content-wrap">
                        <div className="header__dropdown-content white-space-nowrap">
                            {items.map(elem=>
                                <a href="#" className="header__dropdown-content-link" onClick={(e)=>handleClick(e,elem)}>
                                    <span className="header__dropdown-content-link-icon">
                                        <img src={elem.icon} alt='icon'/>
                                    </span>
                                    <span>{elem.title}</span>
                                </a>)
                            }
                        </div>
                    </div>
                </div>
    )
}

export default NavDropdown