import {useState,useEffect,useRef} from 'react'



const WalletItem = ({img,title,handleClick,}) =>{

    return (
        <li className="connect-wallet__item" onClick={handleClick}>
            <a href="#" className="btn">
                <div className="mb-10">
                    <img src={img} alt='icon'/>
                </div>
                <span>{title}</span>
            </a>
        </li>
    )
}

export default WalletItem